<template>
  <div class="content">
    <img src="../assets/img/back.png" @click="back" alt="" />
    <!-- <div
      style="
        position: absolute;
        right: 0;
        top: 1rem;
        width: 0.56rem;
        background: #e7bd52;
        text-align: center;
        border-radius: 5px 0 0 5px;
        color: #fff;
      "
      @click="tuikuan"
    >
      自助退款
    </div> -->
    <div class="title">恭喜您获得!</div>
    <div class="goods_box">
      <div class="goods">
        <van-swipe
          class="my-swipe"
          :autoplay="3000"
          :show-indicators="false"
          @change="onChange"
        >
          <van-swipe-item v-for="(item, index) of goods_box" :key="index">
            <img class="goods_image" :src="item.prize_icon" alt="" />
          </van-swipe-item>
          <template #indicator="{ active, total }">
            <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
          </template>
        </van-swipe>
      </div>
      <div
        v-if="goods_box && goods_box.length > 0"
        class="goods_name onelist-hidden"
      >
        {{ goods_box[activeIndex].prize_name }}
      </div>
      <div v-if="goods_box && goods_box.length > 0" class="goods_type">
        {{ goods_box[activeIndex].prize_level }}
      </div>
      <!-- <div class="submit" @click="submit(1)">
        <div>再抽一次</div>
        <img src="../assets/img/jinbi.png" alt="" />
        <div class="submit_bg">
          <div class="price">￥69</div>
          <div class="newPrice">￥{{ pay_price }}</div>
        </div>
      </div>
      <div class="submit5" @click="submit(5)">
        <div>再抽五次</div>
        <img src="../assets/img/jinbi.png" alt="" />
        <div class="submit_bg" style="width: 2.8rem; height: 0.76rem">
          <div class="price">￥345</div>
          <div class="newPrice">￥{{ Number(pay_price) * 5 }}</div>
        </div>
      </div> -->
      <div v-if="goods_box[0].is_apple_send === 0" class="submit" @click="tihuo">
        <div style="width: 100%; text-align: center">立即提货</div>
      </div>
      <div class="submit5" @click="tousu">
        <div style="width: 100%; text-align: center">咨询客服</div>
      </div>
      <!-- <div style="margin-top: 0.48rem" @click="goAd">
        <img
          src="https://wwh-web.oss-cn-beijing.aliyuncs.com/duanju_img/720-200-%E6%88%91%E7%9A%84%E8%85%B0%E5%B0%81banner.png"
          alt=""
          style="width: 100%"
        />
      </div> -->
      <div class="text" v-if="goods_box[0].is_apple_send === 0">前往APP-我的-我的仓库-选择-提货</div>
      <div class="text" v-if="goods_box[0].is_apple_send === 1">商品提货成功, 将在一周内将您中奖的商品快递发货给您</div>
      <div class="downLoad">
        <div class="downLoad_a" @click="dowond_a">
          <img src="../assets/img/Android_d.png" alt="" />
          <div>Android下载</div>
        </div>
        <!-- <div class="downLoad_i" @click="dowond_i">
          <img src="../assets/img/iPhone_d.png" alt="" />
          <div>iPhone下载</div>
        </div> -->
      </div>
    </div>

    <!-- <div class="success_pay" v-if="showsuccess_pay">
          <div class="content_mask">
              <img class="tishi" src="../assets/img/tisi.png" alt="">
              <div style="font-size: 0.28rem;color: #FFFFFF;font-weight: 500;margin-top: 0.38rem;">确认是否已支付完成？</div>
              <div class="btn_pay">
                  <div class="btn_left">未支付</div>
                  <div class="btn_right" @click="getData({user_id,order_id})">已支付</div>
              </div>
          </div>
      </div> -->
    <van-overlay :show="showsuccess_pay" @click="show = false">
      <div class="wrapper" @click.stop>
        <van-loading size="28px" type="spinner" color="#1989fa" vertical>
          <span>等待支付结果...</span>
          <div class="wrapper_back" @click="back">不等了，返回首页再看看呢</div>
        </van-loading>
      </div>
    </van-overlay>
    <van-dialog
      v-model:show="showShop"
      title="提货信息"
      confirmButtonText="确认提货"
      :before-close="sureConfirm"
      show-cancel-button
    >
      <div>
        <van-form>
          <van-cell-group inset>
            <van-field
              v-model="phone"
              type="number"
              name="电话号码"
              label="电话号码"
              placeholder="电话号码"
              :rules="[{ required: true, message: '请填写电话号码' }]"
            />
            <van-field
              v-model="result"
              is-link
              readonly
              name="picker"
              label="所属城市"
              placeholder="点击选择城市"
              @click="showPicker = true"
            />
            <van-field
              v-model="addrss_detail"
              name="详细地址"
              label="详细地址"
              placeholder="详细地址"
              :rules="[{ required: true, message: '请填写详细地址' }]"
            />

            <div style="font-size: 0.3rem; color: red">
              注意：提货信息填写错误会导致订单发货发错,提货申请完成后请前往app进行查看物流信息
            </div>
          </van-cell-group>
        </van-form>
      </div>
    </van-dialog>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="columns"
        :columns-field-names="{ text: 'label' }"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
const throttle = (func, delay) => {
  // 缓存一个定时器
  let timer = null;
  // 这里返回的函数是每次用户实际调用的节流函数
  return function (...args) {
    if (!timer) {
      //判断timer是否有值,如果没有则说明定时器不存在即可继续执行
      timer = setTimeout(() => {
        //关
        func.apply(this, arguments);
        timer = null; //开
      }, delay);
    }
  };
};
import axios from "axios";
import { showToast } from "vant";
export default {
  data() {
    return {
      goods_box: [],
      data: {},
      user_id: "",
      order_id: "",
      goods_id: "",
      phone: "",
      activeIndex: 0,
      showsuccess_pay: true,
      channel_id: "",
      pay_price: 0,
      pay_type: "",
      T: "",
      callback_url: "",
      openid: "",
      showShop: false,
      showPicker: false,
      columns: [],
      result: "",
      addrss_detail: "",
      tihuoObj: {
        province: "",
        province_id: "",
        city: "",
        city_id: "",
        district: "",
        district_id: "",
        detail: "",
      },
    };
  },
  created() {
    this.getCityList();
    const {
      user_id,
      order_id,
      goods_id,
      phone,
      channel_id,
      pay_price,
      pay_type,
      callback_url,
      openid,
    } = this.$route.query;
    this.user_id = user_id;
    this.order_id = order_id;
    this.goods_id = goods_id;
    this.phone = phone;
    this.openid = openid;
    this.channel_id = channel_id;
    this.pay_price = pay_price;
    this.pay_type = pay_type;
    this.callback_url = callback_url;
    if (this.user_id) {
      this.loopOrderState({
        user_id: this.user_id,
        order_id: this.order_id,
        channel_id: this.channel_id,
      });
    }

    // var bool = false; //定义一个变量
    // window.addEventListener('pageshow', function () {
    //     bool = false; //进入页面时bool置为false，防止ios立即执行popstate
    //     setTimeout(function(){ //定时器延迟将bool置为true
    //         bool = true;
    //     },500)

    // });
    // window.addEventListener("popstate", function(e) {
    //     if(bool){
    //         window.location.href = decodeURIComponent(atob(this.callback_url));
    //     };
    // }, false);
  },
  methods: {
    sureConfirm(e) {
      console.log(e);
      if (e == "cancel") {
        return true;
      }
      if (!this.phone) {
        showToast("请输入手机号");
        return false;
      }
      if (!this.tihuoObj.province) {
        showToast("请选择省市区");
        return false;
      }
      if (!this.addrss_detail) {
        showToast("请输入详细地址");
        return false;
      }
      let data = {
        ...this.tihuoObj,
        order_id: this.order_id,
        mobile: this.phone,
        detail: this.addrss_detail,
        prize_id: this.goods_box[0].order_id,
      };
      axios.post("/api/v1/box/order/delivery", data).then((res) => {
        if (res.data.code == 0) {
          showToast(res.data.msg);
          this.showShop = false;
        } else {
          showToast(res.data.msg);
        }
      });
    },
    getCityList() {
      axios.get("/api/v1/default/districtList").then((res) => {
        console.log(res.data.data);
        this.columns = res.data.data;
      });
    },
    resetObj() {
      this.tihuoObj = {
        province: "",
        province_id: "",
        city: "",
        city_id: "",
        district: "",
        district_id: "",
      };
    },
    onConfirm(e) {
      this.result = "";
      this.resetObj();
      e.selectedOptions.forEach((element, index) => {
        if (index == e.selectedOptions.length - 1) {
          this.result += element.label;
        } else {
          this.result += element.label + "-";
        }
      });
      this.tihuoObj = {
        province: e.selectedOptions[0].label,
        province_id: e.selectedOptions[0].value,
        city: e.selectedOptions[1].label,
        city_id: e.selectedOptions[1].value,
        district: e.selectedOptions[2].label,
        district_id: e.selectedOptions[2].value,
      };
      this.showPicker = false;
      console.log(e);
    },
    tihuo() {
      this.showShop = true;
    },
    tousu() {
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc8db3590c24a5ca09";
    },
    tuikuan() {
      window.location.href =
        "https://video-h5.baishiwang.xyz/?type=2#/pages/tuikuan/tuikuan";
    },
    goAd() {
      window.location.href = "https://u.tuiabcedc.cn/2c14";
    },
    loopOrderState(data) {
      //轮询当前订单状态s
      let that = this;
      let num = 0;
      that.T = setInterval(() => {
        axios
          .post("/api/v1/box/payOrderSuNotLogin", data)
          .then((res) => {
            if (res.data.code == 0) {
              clearInterval(that.T);
              that.goods_box = res.data.data.list[0].prize;
              that.showsuccess_pay = false;
            } else {
              num++;
              if (num > 9) {
                that.callback();
              }
            }
          })
          .catch((err) => {
            console.log(err)
            clearInterval(that.T);
            showToast("网络错误，请稍后重试");
            this.showsuccess_pay = false;
          });
      }, 1000);
    },
    callback() {
      clearInterval(this.T);
      showToast("订单未支付");
      setTimeout(() => {
        window.location.href = decodeURIComponent(atob(this.callback_url));
      }, 500);
    },
    onChange(e) {
      this.activeIndex = e;
    },
    back() {
      window.location.href = decodeURIComponent(atob(this.callback_url));
    },
    dowond_a() {
      window.open(
        "https://oss.aliyun.zhijiezuji.com/apk/lxmh.apk"
      );
    },
    dowond_i() {
      window.open("https://apps.apple.com/cn/app/id1604779545");
    },
    getData(data) {
      axios
        .post("/api/v1/box/payOrderSuNotLogin", data)
        .then((res) => {
          if (res.data.code == 0) {
            this.goods_box = res.data.data.list[0].prize;
            this.showsuccess_pay = false;
          } else {
            this.showsuccess_pay = true;
            showToast(res.data.msg);
          }
        })
        .catch(() => {
          this.showsuccess_pay = true;
          showToast(res.data.msg);
        });
    },
    submit(num) {
      const { pay_price, phone, pay_type, goods_id, channel_id } = this;
      let url = "";
      if (this.openid) {
        let datas = {
          pay_price: 59,
          phone: phone,
          pay_type: 5,
          channel_id,
          goods_id,
          callback_url: decodeURIComponent(atob(this.callback_url)),
          pay_mode: 1,
          goods_name: "手机",
          return_url: window.location.origin + "/#/success",
          openid: this.openid,
        };
        axios
          .post(`${window.location.origin}/api/v1/tui/orderSubmit3`, datas)
          .then((res) => {
            if (res.data.code == 0) {
              // if(that.payIcon==1){
              //     let arr=[{key:'coupon_id',value:1},{key:'payIcon',value:that.payIcon},{key:'phone',value:that.encode(that.phone)},{key:'order_id',value:res.data.data.order_id},{key:'channel_id',value:res.data.data.channel_id}]
              //     arr.forEach(item=>{
              //         let newurl = that.updateQueryStringParameter(window.location.href, item.key, item.value);
              //         window.history.replaceState({
              //             path: newurl
              //         }, '', newurl);
              //     })
              // }
              // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
              window.location.href = res.data.data.pay_data;
            } else {
              showToast(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast(err);
          });
      } else {
        axios
          .post(`${window.location.origin}/api/v1/tui/orderSubmit`, {
            pay_price: Number(pay_price) * num,
            phone,
            pay_type,
            pay_mode: num == 1 ? 1 : 3,
            goods_id,
            channel_id,
            callback_url: decodeURIComponent(atob(this.callback_url)),
            goods_name: "手机",
            return_url: window.location.origin + "/#/success",
          })
          .then(function (res) {
            if (res.data.code == 0) {
              window.location.href = res.data.data.pay_data;
            } else {
              showToast("下单失败");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    toDowond: throttle(function () {
      let data = JSON.parse(localStorage.getItem("adv_m_id"));
      let adv_m_id = this.get_object_first_attribute(data, 1);
      axios.post("/api/v1/tuia/subTam", {
        adv_m_id,
        order_id: this.order_id,
        adv_id: localStorage.getItem("adv"),
        plid: localStorage.getItem("plid"),
      });
    }, 800),
    get_object_first_attribute(data, val) {
      for (var key in data) {
        if (val) {
          return data[key];
        } else {
          return key;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-image: url("../assets/img/success_bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 0.4rem;
  .title {
    font-size: 0.72rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    background: linear-gradient(0deg, #4e35db 0%, #9d4bf1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .goods_box {
    margin-top: 0.4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .goods {
      width: 5.71rem;
      height: 5.71rem;
      background-image: url("../assets/img/goods_bg.png");
      background-size: 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0.8rem;
      .my-swipe {
        width: 3.2rem;
        height: 2.03rem;
        position: relative;
        .custom-indicator {
          position: absolute;
          right: 5px;
          bottom: 5px;
          padding: 2px 5px;
          font-size: 12px;
          background: rgba(0, 0, 0, 0.1);
          color: #1d0e45;
        }
      }
      .goods_image {
        width: 3.2rem;
        height: 2.03rem;
      }
    }
    .goods_name {
      width: 100%;
      margin-top: 0.59rem;
      font-size: 0.48rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
    .goods_type {
      margin-top: 0.27rem;
      width: 2.13rem;
      height: 0.75rem;
      background: linear-gradient(0deg, #7041df 0%, #fedcda 70%, #be84ea 100%);
      border-radius: 0.37rem;
      text-align: center;
      line-height: 0.75rem;
      font-size: 0.33rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #1d0e45;
    }
    .submit {
      margin-top: 1.15rem;
      width: 7.47rem;
      height: 1.17rem;
      background: linear-gradient(90deg, #3cb6ff 0%, #5dd2fe 100%);
      border-radius: 0.59rem;
      display: flex;
      align-items: center;
      padding: 0.37rem 0.95rem 0.35rem 1.2rem;
      box-sizing: border-box;
      font-size: 0.48rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
    .submit5 {
      margin-top: 0.48rem;
      width: 7.47rem;
      height: 1.17rem;
      background: linear-gradient(0deg, #ea60f2 0%, #e74fd5 100%);
      border-radius: 0.59rem;
      display: flex;
      align-items: center;
      padding: 0.37rem 0.6rem 0.35rem 0.9rem;
      box-sizing: border-box;
      font-size: 0.48rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
    .submit_bg {
      width: 2.4rem;
      height: 0.66rem;
      background-image: url("../assets/img/kuang.png");
      background-size: 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      padding: 0 0.15rem 0 0.27rem;
      box-sizing: border-box;
      .price {
        font-size: 0.27rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        text-decoration: line-through;
        color: #3e3e97;
      }
      .newPrice {
        font-size: 0.35rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #3e3e97;
      }
    }
    .text {
      margin-top: 0.89rem;
      font-size: 0.37rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000028;
    }
    .downLoad {
      margin-top: 0.83rem;
      margin-bottom: 0.83rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        width: 4.53rem;
        height: 1.17rem;
        border: 1px solid #1b1b1b;
        border-radius: 0.59rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.31rem 0.77rem;
        box-sizing: border-box;
        font-size: 0.37rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #022222;
      }
      .downLoad_a {
        margin-right: 0.27rem;
      }
    }
  }
}
.success_pay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  //   z-index: 999999;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.content_mask {
  width: 7rem;
  height: 4.61rem;
  background-image: url("../assets/img/success_pay.png");
  background-size: 100% 4.61rem;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tishi {
  width: 1.43rem;
  height: 1.2rem;
}
.btn_pay {
  margin-top: 0.74rem;
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  font-weight: 400;
  color: #ffffff;
}
.btn_left {
  width: 2.3rem;
  height: 0.88rem;
  background: rgba(238, 238, 238, 0.2);
  border-radius: 0.34rem;
  text-align: center;
  line-height: 0.88rem;
}

.btn_right {
  width: 2.3rem;
  height: 0.88rem;
  background: linear-gradient(90deg, #3cb6ff 0%, #5dd2fe 100%);
  border-radius: 0.34rem;
  text-align: center;
  line-height: 0.88rem;
  margin-left: 0.41rem;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  .wrapper_back {
    border-radius: 5px;
    background: #ffffff;
    text-align: center;
    padding: 6px 0;
    margin-top: 30px;
    width: 200px;
    color: #000028;
  }
}
</style>

<template>
  <div>
    <div
      v-show="loadingShow"
      style="
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div>
        <div style="text-align: center">
          <img
            src="https://kdhz-web.oss-cn-chengdu.aliyuncs.com/duanju_img/order_detail.jpg"
            alt=""
            style="width: 3rem"
          />
        </div>
        <van-loading
          size="36px"
          type="spinner"
          color="rgb(0, 140, 255)"
          vertical
        >
          <div>订单加载中...</div>
          <div></div>
        </van-loading>
      </div>
    </div>
    <div class="view" v-show="loadingShow === false">
      <div class="hearder">
        订单支付
        <img
          class="img"
          @click="back"
          src="../assets/img/back_icon.png"
          alt=""
        />
      </div>
      <van-form :data="recipient" @submit="getPayment">
        <div class="content">
          <div style="color: #1c1c1c; font-weight: 400; font-size: 0.48rem">
            商品价格
          </div>
          <div
            style="
              color: #333333;
              font-weight: 400;
              font-size: 0.74rem;
              margin: 0.4rem 0 0.53rem;
            "
          >
            <span style="font-size: 0.43rem">¥</span> {{ pay_price }}
          </div>
          <div style="width: 100%">
            <van-cell-group inset>
              <van-field
                v-model="recipient.name"
                :maxlength="20"
                name="name"
                label="收件人"
                placeholder="收件人"
                clearable
                :rules="[{ required: true, message: '请输入收件人' }]"
              ></van-field>
              <van-field
                v-model="recipient.mobile"
                :maxlength="11"
                name="mobile"
                label="手机号码"
                placeholder="手机号码"
                clearable
                :rules="[{ pattern, message: '手机号码格式错误' }]"
              ></van-field>
              <van-field
                v-model="areaShow"
                is-link
                readonly
                name="area"
                label="收件地址"
                placeholder="点击选择省市区"
                :rules="[{ required: true, message: '请选择手机地址' }]"
                @click="showArea = true"
              >
              </van-field>
              <van-popup v-model:show="showArea" position="bottom">
                <van-area
                  :area-list="areaList"
                  @confirm="areaChoose"
                  @cancel="showArea = false"
                />
              </van-popup>
              <van-field
                v-model="recipient.detail"
                :maxlength="100"
                name="address"
                label="详细地址"
                placeholder="详细地址"
                clearable
                :rules="[{ required: true, message: '请输入详细地址' }]"
              ></van-field>
              <div style="color: red; font-size: 0.35rem">
                提示：提交订单并完成支付后，将自动完成提货操作，错误的填写收货地址将导致的发货不准确。系统将在一周内完成发货，请耐心等待。
              </div>
            </van-cell-group>
          </div>
          <div v-if="payType === 'success'" class="pay-channel">
            <label>
              <van-radio
                name="zhifubao"
                value="1"
                class="pay-channel-radio"
                checked-color="#07c160"
                checked
              >
                <span style="font-size: 20px">微信付款</span>
              </van-radio>
            </label>
          </div>
          <div v-if="payType === 'primary'" class="pay-channel">
            <label>
              <van-radio
                name="zhifubao"
                value="1"
                class="pay-channel-radio"
                checked
              >
                <span style="font-size: 20px">支付宝支付</span>
              </van-radio>
            </label>
          </div>
        </div>
        <div class="button">
          <van-button block :type="payType" native-type="submit"
            >提交并支付</van-button
          >
          <div style="margin-top: 10px">
            <van-checkbox checked>
              <span style="font-size: 0.3rem; color: #999999">
                提交订单将视为同意
              </span>
              <a
                style="font-size: 0.3rem; color: #3b10f7"
                href="https://mh.zhijiezuji.com/yonghu"
                >《用户协议》</a
              >
              <span style="font-size: 0.3rem; color: #999999">和</span>
              <a
                style="font-size: 0.3rem; color: #3b10f7"
                href = "https://mh.zhijiezuji.com/privacy"
                >《隐私协议》</a
              >
              <span style="font-size: 0.3rem; color: #999999">及</span>
              <a
                style="font-size: 0.3rem; color: #3b10f7"
                href = "https://mh.zhijiezuji.com/xuzhi"
                >《规则须知》</a
              >
            </van-checkbox>
          </div>
          <!-- <div class="btn" @click="getPayment">支付宝付款</div> -->
        </div>
      </van-form>
    </div>

    <!-- <div v-show="loadingShow" class="loading-mask">
      <div class="loading-view">
        <van-loading></van-loading>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import checkbox from "uview-ui/libs/config/props/checkbox";
import { showToast } from "vant";
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      goods_id: "",
      channel_id: "",
      pay_price: "0",
      phone: "",
      pay_type: "",
      pay_mode: "",
      adv_m_id: "",
      adv_client_id: "",
      adv_client_appid: "",
      channel_no: "",
      coupon_id: "",
      payIcon: "",
      buttonChange: false,
      platfrom: "",
      order_url_c: "",
      loadingShow: true,
      areaShow: "",
      showArea: false,
      recipient: {
        name: "",
        mobile: "",
        province: "",
        province_id: "",
        city: "",
        city_id: "",
        district: "",
        district_id: "",
        detail: "",
      },
    };
  },
  setup() {
    const pattern = /^1[3-9]\d{9}$/;
    return {
      payType: 'primary',
      pattern,
      areaList,
    };
  },
  created() {
    this.goods_id = this.getQueryString("goods_id");
    this.payIcon = this.getQueryString("payIcon");
    this.channel_id = this.getQueryString("channel_id");
    this.pay_price = this.getQueryString("pay_price");
    this.phone = this.getQueryString("phone");
    this.pay_type = this.getQueryString("pay_type");
    this.pay_mode = this.getQueryString("pay_mode");
    this.adv_m_id =
      this.getQueryString("adv_m_id") == "undefined" ||
      !this.getQueryString("adv_m_id")
        ? ""
        : this.getQueryString("adv_m_id");
    this.adv_client_id = this.getQueryString("adv_client_id");
    this.adv_client_appid = this.getQueryString("adv_client_appid");
    this.callback_url = this.getQueryString("callback_url");
    this.channel_no = this.getQueryString("channel_no");
    this.coupon_id = this.getQueryString("coupon_id");
    this.platfrom = this.getQueryString("platfrom");
  },
  mounted() {
    //畅连支付监听页面进入后台并且是点击按钮后进入后台的
    document.addEventListener("visibilitychange", () => {
      if (
        this.buttonChange &&
        (this.getQueryString("return_url") || this.order_url_c) &&
        !document.hidden
      ) {
        console.log(504);
        this.buttonChange = false;
        if (this.getQueryString("return_url")) {
          window.location.href = unescape(this.getQueryString("return_url"));
        }
        if (this.isBaiDu()) {
          window.location.href = unescape(this.order_url_c);
        }
        window.location.href = unescape(this.getQueryString("return_url"));
      }
    });
    this.$nextTick(() => {
      //真对短剧
      if (this.getQueryString("order_id")) {
        this.loopOrderState();
      } else {
        // this.getPayment();
        this.loadingShow = false;
      }
    });
  },
  methods: {
    areaChoose({ selectedOptions }) {
      const chooseData = selectedOptions.map((item) => item.text);
      this.areaShow = chooseData.join("-");
      this.recipient.area = chooseData.join("");
      this.recipient.province = selectedOptions[0].text;
      this.recipient.province_id = selectedOptions[0].code;
      this.recipient.city = selectedOptions[1].text;
      this.recipient.city_id = selectedOptions[1].code;
      this.recipient.district = selectedOptions[2].text;
      this.recipient.district_id = selectedOptions[2].code;
      this.showArea = false;
    },
    isBaiDu() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (sUserAgent.indexOf("baidu") > 0) {
        return true;
      }
      return false;
    },
    loopOrderState() {
      //轮询当前订单状态s
      let that = this;
      let num = 0;
      let data = {
        order_id: this.getQueryString("order_id"),
        channel_id: this.getQueryString("channel_id"),
      };
      that.T = setInterval(() => {
        axios
          .post(`${window.location.origin}/api/v1/video/payOrderSuccess`, data)
          .then((res) => {
            if (res.data.code == 0) {
              clearInterval(that.T);
              window.location.href =
                "https://work.weixin.qq.com/kfid/kfc8db3590c24a5ca09";
            } else {
              num++;
              if (num > 9) {
                that.callback();
              }
            }
          })
          .catch(() => {
            clearInterval(that.T);
            showToast("网络错误，请稍后重试");
            this.showsuccess_pay = false;
          });
      }, 1000);
    },
    callback() {
      clearInterval(this.T);
      showToast("订单未支付");
      setTimeout(() => {
        window.location.href = decodeURIComponent(atob(this.callback_url));
      }, 500);
    },
    paySucc() {
      let data = {
        order_id: this.getQueryString("order_id"),
        channel_id: this.getQueryString("channel_id"),
      };
      axios
        .post(`${window.location.origin}/api/v1/video/payOrderSuccess`, data)
        .then((res) => {
          if (res.data.code == 0) {
            window.location.href =
              "https://work.weixin.qq.com/kfid/kfc8db3590c24a5ca09";
          }
        })
        .catch((err) => {
          showToast("未查询到已支付订单");
        });
    },
    back() {
      window.history.go(-1);
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    encode(str) {
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
    updateQueryStringParameter(uri, key, value) {
      if (!value) {
        return uri;
      }
      uri = uri.split("#/").join("");
      var re = new RegExp("(^|&)" + key + "=([^&]*)(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.indexOf(key) !== -1) {
        return uri + "#/";
      }
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2") + "#/";
      } else {
        return uri + separator + key + "=" + value + "#/";
      }
    },
    getPayment(e) {
      let that = this;
      const {
        pay_price,
        pay_type,
        goods_id,
        pay_mode,
        adv_m_id,
        adv_client_id,
        channel_id,
        callback_url,
        channel_no,
        coupon_id,
        payIcon,
        platfrom,
      } = this;
      const phone = this.recipient.mobile;
      this.loadingShow = true;
      // try {
      if (
        this.getQueryString("payVideo") == 1 ||
        this.getQueryString("payVideo") == 2
      ) {
        let datas = {
          pay_price: 59,
          pay_type: 1,
          channel_no: channel_no,
          channel_id: channel_id,
          coupon_id,
          goods_id: this.getQueryString("payVideo"),
          callback_url:
            callback_url + `/?channel_no=${channel_no}&coupon_id=1#/`,
          pay_mode: 1,
          goods_name: "手机",
          return_url: window.location.origin + "/#/vedioSuccess",
          adv_m_id,
          adv_client_id,
        };
        axios
          .post(`${window.location.origin}/api/v1/video/submit`, datas)
          .then((res) => {
            let order_url = escape(res.data.data.return_url);
            if (res.data.code == 0) {
              if (payIcon == 2) {
                let arr = [
                  { key: "coupon_id", value: 1 },
                  { key: "payIcon", value: payIcon },
                  { key: "order_id", value: res.data.data.order_id },
                  { key: "channel_id", value: res.data.data.channel_id },
                ];
                arr.forEach((item) => {
                  let newurl = that.updateQueryStringParameter(
                    window.location.href,
                    item.key,
                    item.value
                  );
                  window.history.replaceState(
                    {
                      path: newurl,
                    },
                    "",
                    newurl
                  );
                });
              }
              if (platfrom == "6" || platfrom == "11" || platfrom == "13") {
                //证明用户点击过后才进入后台
                that.buttonChange = true;
                // that.returnUrl=res.data.data.return_url
                let newurl = that.updateQueryStringParameter(
                  window.location.href,
                  "return_url",
                  order_url
                );
                window.history.replaceState(
                  {
                    path: newurl,
                  },
                  "",
                  newurl
                );
              }
              // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
              if (platfrom == "13") {
                that.iframe_rul = "";
                let url = res.data.data.pay_data.split("//");
                let toUrl = res.data.data.pay_data;
                that.iframe_rul = toUrl.slice(29);
                // url.splice(4,0,'convertPayway')
                let src = url[1].split("/");
                src.splice(3, 0, "convertPayway");
                let sure = src.join("/");
                url = url[0] + "//" + sure;
                axios
                  .post(url, {
                    wayCode: that.payIcon == 2 ? "ALI_WAP" : "WX_H5",
                    payDataType: "",
                  })
                  .then((bizData) => {
                    if (bizData.data.data.payDataType == "payurl") {
                      if (that.payIcon == 2) {
                        if (that.isBaiDu()) {
                          let iframe = document.getElementById("iframe1");
                          const iframeWindow = iframe.contentWindow;
                          // alert(iframeWindow)
                          setTimeout(() => {
                            iframeWindow.document
                              .getElementsByClassName("paycashier-type")[3]
                              .click();
                            iframeWindow.document
                              .getElementsByClassName("confirmBtn")[0]
                              .click();
                          }, 2000);
                        } else {
                          window.location.href = bizData.data.data.payData;
                        }
                      } else {
                        var m =
                          bizData.data.data.payData.match(/wxaurl\.cn\/(\w+)/);
                        if (m && m[1]) {
                          let mockUrlScheme = `weixin://dl/business/?t=${m[1]}`;
                          window.location.href = mockUrlScheme;
                        }
                      }
                    }
                  });
              } else {
                window.location.href = res.data.data.pay_data;
              }
            } else {
              showToast(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast(err);
          });
      }
      //电费
      else if (this.getQueryString("payVideo") == "powerRate") {
        let datas = {
          pay_price: 38.8,
          pay_type: 1,
          channel_no: channel_no,
          channel_id: channel_id,
          coupon_id,
          goods_id: goods_id,
          callback_url: callback_url + `/?channel_no=${channel_no}#/`,
          pay_mode: 1,
          goods_name: "手机",
          return_url: window.location.origin + "/#/powerOldSuccess",
          adv_m_id,
          adv_client_id,
        };
        axios
          .post(
            `${window.location.origin}/api/v1/electric/couponSubmitOrder`,
            datas
          )
          .then((res) => {
            if (res.data.code == 0) {
              // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
              window.location.href = res.data.data.pay_data;
            } else {
              showToast(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast(err);
          });
      }
      //盲盒
      else {
        axios
          .post(`${window.location.origin}/api/v1/tui/orderSubmitAndTake`, {
            pay_price,
            phone,
            pay_type,
            channel_id,
            coupon_id,
            goods_id,
            take: that.recipient,
            callback_url:
              callback_url +
              `/?channel_no=${channel_no}&coupon_id=1&phone=${that.encode(
                phone
              )}#/`,
            pay_mode,
            goods_name: "手机",
            return_url: window.location.origin + "/#/success",
            adv_m_id,
            adv_client_id,
          })
          .then((res) => {
            let order_url = escape(res.data.data.return_url);
            that.order_url_c = escape(res.data.data.return_url);
            if (res.data.code == 0) {
              if (
                platfrom == "1" ||
                platfrom == "6" ||
                platfrom == "11" ||
                platfrom == "13" ||
                platfrom == "16" ||
                platfrom == "17"
              ) {
                //证明用户点击过后才进入后台
                that.buttonChange = true;
                // that.returnUrl=res.data.data.return_url
                let newurl = that.updateQueryStringParameter(
                  window.location.href,
                  "return_url",
                  order_url
                );
                window.history.replaceState(
                  {
                    path: newurl,
                  },
                  "",
                  newurl
                );
              }
              // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
              if (platfrom == "13") {
                let url = res.data.data.pay_data.split("//");
                // url.splice(4,0,'convertPayway')
                let src = url[1].split("/");
                src.splice(3, 0, "convertPayway");
                let sure = src.join("/");
                url = url[0] + "//" + sure;
                axios
                  .post(url, {
                    wayCode: that.payIcon == 2 ? "ALI_WAP" : "WX_H5",
                    payDataType: "",
                  })
                  .then((bizData) => {
                    if (bizData.data.data.payDataType == "payurl") {
                      if (that.payIcon == 2) {
                        window.location.href = bizData.data.data.payData;
                      } else {
                        var m =
                          bizData.data.data.payData.match(/wxaurl\.cn\/(\w+)/);
                        if (m && m[1]) {
                          let mockUrlScheme = `weixin://dl/business/?t=${m[1]}`;
                          window.location.href = mockUrlScheme;
                        }
                      }
                    }
                  });
              } else {
                window.location.href = res.data.data.pay_data;
              }
            } else {
              console.log(res);
              showToast(res.data.msg);
              setTimeout(() => {
                this.back();
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast(err);
            setTimeout(() => {
              this.back();
            }, 3000);
          });
      }
      // } catch (error) {
      //   throw error;
      // } finally {
      //   loadingShow = false;
      // }
    },
    get_object_first_attribute(data, val) {
      for (var key in data) {
        if (val) {
          return data[key];
        } else {
          return key;
        }
      }
    },
  },
  components: { checkbox },
};
</script>

<style lang="less" scoped>
.view {
  width: 100%;
  height: 100vh;
  background: #f7f7f7;
}
.hearder {
  text-align: center;
  font-size: 0.45rem;
  font-weight: 400;
  color: #333333;
  padding: 0.4rem;
  background: #fff;
  position: relative;
  .img {
    width: 0.8rem;
    height: 0.96rem;
    position: absolute;
    left: 0.27rem;
    top: 50%;
    margin-top: -0.48rem;
  }
}
.content {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  min-height: 4.8rem;
  width: 100%;
}
.button {
  padding: 0 0.12rem;
  margin-top: 1.29rem;
  .btn {
    width: 100%;
    height: 1.17rem;
    background: linear-gradient(90deg, #2af801 0%, #5dfe68 100%);
    border-radius: 0.89rem;
    font-size: 0.43rem;
    font-weight: 400;
    color: #fefefe;
    text-align: center;
    line-height: 1.17rem;
  }
}

.pay-channel {
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  padding: 40px;
}

.loading-mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-view {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SuccessView from '../views/SuccessView.vue'
import userAgreement from '../views/userAgreement.vue'
import privacyPage from '../views/privacyPage.vue'
import payPage from '../views/payPage.vue'
import vedioSuccess from '../views/vedioSuccess.vue'
import couponSubmitOrder from '../views/couponSubmitOrder.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView
  },
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: userAgreement
  },
  {
    path: '/privacyPage',
    name: 'privacyPage',
    component: privacyPage
  },
  {
    path: '/payPage',
    name: 'payPage',
    component: payPage
  },
  {
    path: '/vedioSuccess',
    name: 'vedioSuccess',
    component: vedioSuccess
  },
  {
    path: '/powerOldSuccess',
    name: 'powerOldSuccess',
    component: couponSubmitOrder
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
